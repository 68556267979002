<template>
  <div>
    <div class="d-inline-block mr-2 mt-3 mt-sm-0 mb-3 mb-sm-3">
      <a-input-search placeholder="Search by name..." @search="onSearch" />
    </div>
    <div class="mb-4">
      <a-table
        :columns="columns"
        :dataSource="filteredData"
        :pagination="true"
        :class="$style.table"
        rowKey="id"
        :loading="isLoading"
      >
        <template slot="fullname" slot-scope="fullname, record">
          <div>
            <div>
              <router-link
                :to="{ path: '/dokter/details/' + record.id }"
                class="kit__utils__link font-size-16"
                >{{ fullname }}</router-link
              >
            </div>
            <!-- <div v-if="!isAdmin">{{ fullname }}</div> -->
            <div class="text-gray-4">{{ record.code ? record.code : '-' }}</div>
          </div>
        </template>
        <template slot="createdAt" slot-scope="createdAt">
          <div>
            <div>
              {{ createdAt ? moment(createdAt).format('DD MMM YYYY') : '-' }}
            </div>
          </div>
        </template>
        <template slot="email" slot-scope="email">
          <div>
            <div>{{ email }}</div>
          </div>
        </template>
        <template slot="city" slot-scope="city">
          <div>
            <div>{{ city ? city : '-' }}</div>
          </div>
        </template>
        <template slot="verificationCode" slot-scope="verificationCode">
          <div>
            <div>{{ verificationCode ? verificationCode : '-' }}</div>
          </div>
        </template>
        <template slot="hospital" slot-scope="hospital">
          <div>
            <div>{{ hospital }}</div>
          </div>
        </template>
        <template slot="isApproved" slot-scope="isApproved">
          <div>
            <div>
              {{
                isApproved == true
                  ? 'Disetujui'
                  : isApproved == false
                  ? 'Ditolak'
                  : 'Menunggu Verifikasi'
              }}
            </div>
          </div>
        </template>
        <template slot="programTypes" slot-scope="programTypes">
          <div v-for="(programType, i) in programTypes" :key="i">
            {{ programType.name }}
          </div>
        </template>
      </a-table>
    </div>
  </div>
</template>
<script>
import { getAll } from '@/services/axios/api/dokter'
// import { getAll as getAllCity } from '@/services/axios/api/city'
import moment from 'moment'
import fuzzysort from 'fuzzysort'
import router from '@/router'

const columns = [
  {
    title: 'Nama Dokter',
    dataIndex: 'fullname',
    className: 'bg-transparent text-gray-6 width-50',
    scopedSlots: { customRender: 'fullname' },
    fixed: 'left',
    sorter: (a, b) => {
      return a.fullname.localeCompare(b.fullname)
    },
  },
  {
    title: 'E-mail',
    dataIndex: 'email',
    className: 'bg-transparent text-gray-6',
    scopedSlots: { customRender: 'email' },
    sorter: (a, b) => {
      return a.email.localeCompare(b.email)
    },
  },
  {
    title: 'Kode Verifikasi',
    dataIndex: 'verificationCode',
    className: 'bg-transparent text-gray-6',
    scopedSlots: { customRender: 'verificationCode' },
    sorter: (a, b) => {
      return a.verificationCode.localeCompare(b.verificationCode)
    },
  },
  {
    title: 'Kota',
    dataIndex: 'city',
    className: 'bg-transparent text-gray-6',
    scopedSlots: { customRender: 'city' },
    sorter: (a, b) => {
      return a.city.localeCompare(b.city)
    },
    filters: [
      {
        text: 'Bandung',
        value: 'Bandung',
      },
      {
        text: 'Banjarmasin',
        value: 'Banjarmasin',
      },
      {
        text: 'Bogor',
        value: 'Bogor',
      },
      {
        text: 'Bali',
        value: 'Bali',
      },
      {
        text: 'Bekasi',
        value: 'Bekasi',
      },
      {
        text: 'Cirebon',
        value: 'Cirebon',
      },
      {
        text: 'Depok',
        value: 'Depok',
      },
      {
        text: 'Denpasar',
        value: 'Denpasar',
      },
      {
        text: 'DKI Jakarta',
        value: 'DKI Jakarta',
      },
      {
        text: 'Jambi',
        value: 'Jambi',
      },
      {
        text: 'Kendari',
        value: 'Kendari',
      },
      {
        text: 'Medan',
        value: 'Medan',
      },
      {
        text: 'Malang',
        value: 'Malang',
      },
      {
        text: 'Makassar',
        value: 'Makassar',
      },
      {
        text: 'Papua',
        value: 'Papua',
      },
      {
        text: 'Padang',
        value: 'Padang',
      },
      {
        text: 'Purwokerto',
        value: 'Purwokerto',
      },
      {
        text: 'Pekanbaru',
        value: 'Pekanbaru',
      },
      {
        text: 'Palembang',
        value: 'Palembang',
      },
      {
        text: 'Solo',
        value: 'Solo',
      },
      {
        text: 'Semarang',
        value: 'Semarang',
      },
      {
        text: 'Surabaya',
        value: 'Surabaya',
      },
      {
        text: 'Samarinda',
        value: 'Samarinda',
      },
      {
        text: 'Tangerang',
        value: 'Tangerang',
      },
      {
        text: 'Yogyakarta',
        value: 'Yogyakarta',
      },
    ],
    onFilter: (value, record) => {
      return record.city === value
    },
    // filters: [],
    // onFilter: (value, record) => record.city === value,/
  },
  {
    title: 'Rumah Sakit',
    dataIndex: 'hospital',
    className: 'bg-transparent',
    scopedSlots: { customRender: 'hospital' },
    sorter: (a, b) => {
      return a.hospital.localeCompare(b.hospital)
    },
  },
  {
    title: 'Program',
    dataIndex: 'programTypes',
    className: 'bg-transparent',
    scopedSlots: { customRender: 'programTypes' },
    filters: [
      {
        text: 'TAGRISSO',
        value: 'TAGRISSO',
      },
      {
        text: 'FASLODEX',
        value: 'FASLODEX',
      },
      {
        text: 'LYNPARZA',
        value: 'LYNPARZA',
      },
    ],
    onFilter: (value, record) => {
      return record.programTypes.filter((el) => el.name === value).length > 0
    },
  },
  {
    title: 'Tanggal Daftar',
    dataIndex: 'createdAt',
    className: 'bg-transparent text-gray-6 width-50',
    scopedSlots: { customRender: 'createdAt' },
    sorter: (a, b) => {
      return a.createdAt.localeCompare(b.createdAt)
    },
  },
  {
    title: 'Status',
    dataIndex: 'isApproved',
    className: 'bg-transparent text-gray-6 width-50',
    scopedSlots: { customRender: 'isApproved' },
    sorter: (a, b) => {
      if (a.isApproved == null) {
        return -1
      }
      if (b.isApproved == null) {
        return 1
      } else {
        return b.isApproved - a.isApproved
      }
    },
    filters: [
      {
        text: 'Menunggu Verifikasi',
        value: 'null',
      },
      {
        text: 'Ditolak',
        value: false,
      },
      {
        text: 'Disetujui',
        value: true,
      },
    ],
    onFilter: (value, record) => {
      if (value === 'null') return record.isApproved === null
      else return record.isApproved === value
    },
  },
]

export default {
  name: 'TabelDokter',
  data: function () {
    return {
      columns,
      items: [],
      isLoading: true,
      filteredData: [],
      isAdmin: false,
    }
  },
  created() {
    this.getAllDoctor()
    this.getRole()
    // this.getCityList()
  },
  methods: {
    onSearch(searchText) {
      if (searchText !== '') {
        const results = fuzzysort.go(searchText, this.items, {
          key: 'fullname',
        })
        const filteredData = results.map((result) => {
          return result.obj
        })
        console.log(results)
        this.filteredData = filteredData
      } else {
        this.filteredData = this.items
      }
    },
    moment,
    getRole() {
      const role = window.localStorage.getItem('role')
      // console.log(role)
      if (role === 'admin') {
        this.isAdmin = true
      }
    },
    async getAllDoctor() {
      const res = await getAll()
      this.isLoading = false
      console.log(res, 'dokter')
      this.items = res
      this.filteredData = res
    },
    viewDetails(id) {
      router.push('/dokter/details/' + id)
    },
    // async getCityList() {
    //   const res = await getAllCity()
    //   if (res && res.length) {
    //     res.forEach(element => {
    //       this.columns[3].filters.push({
    //         text: element.name,
    //         value: element.name,
    //       })
    //     })
    //   }
    // },
  },
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>
